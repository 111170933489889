import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function UseTerms() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <b onClick={handleClickOpen}> Termos de Uso </b>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Termos de Uso
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            Bem-vindo ao A'Marko! Ao utilizar nossos serviços, você concorda com os seguintes Termos de Uso. Leia-os com atenção antes de utilizar nossa plataforma.
          </Typography>

          <Typography gutterBottom>
            Aceitação dos Termos
          </Typography>
          <Typography gutterBottom>
            Ao acessar e utilizar o site A'Marko e os serviços oferecidos, você concorda em cumprir e estar legalmente vinculado a estes Termos de Uso. Se você não concorda com alguma parte dos termos, não deve utilizar nossos serviços.
          </Typography>

          <Typography gutterBottom>
            Serviços Oferecidos
          </Typography>
          <Typography gutterBottom>
            A'Marko oferece uma plataforma online para agendamento de serviços de beleza, gestão de profissionais e um painel administrativo para os estabelecimentos cadastrados. Os recursos variam conforme o plano escolhido (Básico, Pro ou Premium).
          </Typography>

          <Typography gutterBottom>
            Cadastro de Conta
          </Typography>
          <Typography gutterBottom>
            Para utilizar nossos serviços, você deve criar uma conta com informações válidas. Você é responsável por manter a segurança de suas credenciais de acesso e pela precisão das informações fornecidas.
          </Typography>

          <Typography gutterBottom>
            Planos de Assinatura e Pagamentos
          </Typography>
          <Typography gutterBottom>
            A'Marko oferece diferentes planos de assinatura, cada um com seus respectivos preços e funcionalidades. As assinaturas são pagas mensalmente, e o não pagamento pode resultar na suspensão ou cancelamento da conta.
          </Typography>

          <Typography gutterBottom>
            Cancelamento de Serviços
          </Typography>
          <Typography gutterBottom>
            O usuário pode cancelar a assinatura a qualquer momento. Após o cancelamento, o acesso às funcionalidades premium será removido ao término do período de faturamento vigente. Não haverá reembolsos para períodos já pagos.
          </Typography>

          <Typography gutterBottom>
            Política de Privacidade
          </Typography>
          <Typography gutterBottom>
            O tratamento dos dados pessoais fornecidos durante o uso da plataforma está descrito em nossa Política de Privacidade. É importante que você revise e entenda como suas informações são coletadas e utilizadas.
          </Typography>

          <Typography gutterBottom>
            Uso Adequado da Plataforma
          </Typography>
          <Typography gutterBottom>
            Ao utilizar A'Marko, você concorda em não utilizar nossos serviços para qualquer finalidade ilegal ou proibida por estes Termos. Isso inclui, entre outras práticas, a inserção de informações falsas ou enganosas e a violação de direitos de terceiros.
          </Typography>

          <Typography gutterBottom>
            Modificações nos Termos
          </Typography>
          <Typography gutterBottom>
            Reservamo-nos o direito de alterar ou modificar estes Termos a qualquer momento. As alterações entrarão em vigor após a publicação no site. O uso continuado do A'Marko após essas mudanças será considerado como aceitação das mesmas.
          </Typography>

          <Typography gutterBottom>
            Limitação de Responsabilidade
          </Typography>
          <Typography gutterBottom>
            A'Marko não se responsabiliza por quaisquer danos diretos ou indiretos resultantes do uso de nossa plataforma, incluindo perda de dados ou lucros cessantes. O uso da plataforma é de total responsabilidade do usuário.
          </Typography>

          <Typography gutterBottom>
            Disposições Gerais
          </Typography>
          <Typography gutterBottom>
            Estes Termos de Uso constituem o acordo completo entre você e a A'Marko em relação ao uso dos serviços. Qualquer falha de nossa parte em impor qualquer direito ou disposição destes Termos não constituirá uma renúncia a tais direitos.
          </Typography>

          <Typography gutterBottom>
            Caso tenha dúvidas sobre estes Termos de Uso, entre em contato com nosso suporte.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}