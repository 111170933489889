import React from "react";

import axios from 'axios';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Checkbox from '@mui/material/Checkbox';

import Privacity from "./privacity";
import UseTerms from "./use_terms";

const formatCnpjCpf = (value) => {
  // Remove todos os caracteres que não são números
  const numericValue = value.replace(/\D/g, '');

  if (numericValue.length <= 11) {
    // Formatação para CPF (###.###.###-##)
    return numericValue
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else {
    // Formatação para CNPJ (##.###.###/####-##)
    return numericValue
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/\.(\d{3})(\d)/, '.$1/$2')
      .replace(/(\d{4})(\d{1,2})$/, '$1-$2');
  }
};

const formatTelefone = (value) => {
  // Remove todos os caracteres que não são números
  const numericValue = value.replace(/\D/g, '');

  // Formatação para celular com 9 dígitos (##) 9 ####-#### ou telefone fixo (##) ####-####
  if (numericValue.length > 10) {
    // Celular com 9 dígitos (exemplo: (99) 9 9999-9999)
    return numericValue
      .replace(/^(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{1})(\d{4})(\d)/, '$1 $2-$3');
  } else if (numericValue.length > 2) {
    // Telefone fixo (exemplo: (99) 9999-9999)
    return numericValue
      .replace(/^(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2');
  } else {
    // Exibe apenas o DDD parcial enquanto digita
    return numericValue.replace(/^(\d{0,2})/, '($1');
  }
};

export const Plans = () => {
  const [fantasyName, setFantasyName] = React.useState("");
  const [cnpjCpf, setCnpjCpf] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [planName, setPlanName] = React.useState("");
  const [optinTerms, setOptinTerms] = React.useState(false);

  const [contractModalOpen, setContractModal] = React.useState(false);

  const checkboxLabel = { inputProps: { 'aria-label': 'Optin' } };

  const handleChangePlan = (event) => {
    setPlanName(event.target.value);
  };

  const handleChangeOptin = (event) => {
    setOptinTerms(event.target.checked);
  };

  const handleOpenContractModal = (planValue) => {
    setPlanName(planValue);
    setContractModal(true);
  };

  const handleCloseContractModal = () => {
    setContractModal(false);
  };

  const handleChangeCnpjCpf = (event) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/g, '');

    if (numericValue.length <= 14) {
      const formattedValue = formatCnpjCpf(value); // Formata o valor conforme a quantidade de dígitos
      setCnpjCpf(formattedValue); // Define o valor formatado no estado
    }
  };

  const handleChangeTelefone = (event) => {
    const { value } = event.target;

    // Remove caracteres não numéricos para contar apenas os números
    const numericValue = value.replace(/\D/g, '');

    // Verifica o limite de caracteres: 10 para fixo e 11 para celular com o 9
    if (numericValue.length <= 11) {
      const formattedValue = formatTelefone(value); // Formata o valor conforme a quantidade de dígitos
      setPhone(formattedValue); // Define o valor formatado no estado
    }
  };

  const createContract = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append('fantasy_name', fantasyName);
    formData.append('cnpj_cpf', cnpjCpf);
    formData.append('email', email);
    formData.append('contact_phone', phone);
    formData.append('plan_name', planName);
    formData.append('optin_terms', optinTerms);

    try {
      const response = await axios.post(`${process.env.REACT_APP_URL_BACKEND}/landing_page/register_business`, formData, {
        headers: {
          'Authorization': process.env.REACT_APP_API_KEY,
        }
      });

      if(response.data.message === "success"){
        alert('Cadastro realizado com sucesso, aguarde nossa equipe entrar em contato!');
      }else{
        alert('Erro ao realizar cadastro, tente novamente mais tarde.');
      }

      window.location.reload();
    } catch (error) {
      console.error('Erro ao enviar dados para a API:', error);
    }
  }

  return (
    <div id="plans" className="text-center">
      <div className="container">
        <div>
          <h2>Nossos Planos</h2>
          <p>
            Conheça os planos que temos disponíveis para você.
          </p>
        </div>
        <section className="plans__container">
          <div className="plans">
            <div className="planItem__container">
              <div className="planItem planItem--free">
                <div className="card">
                  <div className="card__header">
                    <div className="card__icon symbol symbol--rounded"></div>
                    <h2 style={{color: "#333", fontSize: "2.5rem", fontWeight: "bold"}}>Básico</h2>
                  </div>
                  <div className="card__desc">Ideal para pequenos negócios. Gerencie seus agendamentos e acompanhe relatórios de desempenho com até 2 profissionais.</div>
                </div>

                <div className="price">Grátis</div>

                <ul className="featureList">
                  <li>Até 2 Profissionais</li>
                  <li>Relatórios</li>
                  <li>Até 100 Agendamentos por Mês</li>
                  <li>Agenda Visual</li>
                  <li>Notificações Agendamento</li>
                  <li className="disabled">Central de Atendimento</li>
                  <li className="disabled">ChatBot de Atendimento</li>
                  <li className="disabled">Suporte Prioritário</li>
                </ul>

                <button onClick={() => {handleOpenContractModal(0)}} style={{fontSize: "1.5rem"}} className="button button--pink">Contratar</button>
              </div>

              <div className="planItem planItem--pro">
                <div className="card">
                  <div className="card__header">
                    <div className="card__icon symbol"></div>
                    <h2 style={{color: "#333", fontSize: "2.5rem", fontWeight: "bold"}}>Pro</h2>
                    <div className="card__label label">Melhor valor</div>
                  </div>
                  <div className="card__desc">Cresça sem limites! Profissionais ilimitados, central de atendimento e relatórios completos para o controle do seu negócio.</div>
                </div>

                <div className="price">R$ 89,90<span>/ mês</span></div>

                <ul className="featureList">
                  <li>Profissionais Ilimitados</li>
                  <li>Relatórios</li>
                  <li>Agendamento Online</li>
                  <li>Central de Atendimento</li>
                  <li>Agenda Visual</li>
                  <li>Notificações Agendamento</li>
                  <li className="disabled">ChatBot de Atendimento</li>
                  <li className="disabled">Suporte Prioritário</li>
                </ul>

                <button onClick={() => {handleOpenContractModal(1)}} style={{fontSize: "1.5rem"}} className="button button--pink">Contratar</button>
              </div>

              <div className="planItem planItem--entp">
                <div className="card">
                  <div className="card__header">
                    <div className="card__icon"></div>
                    <h2 style={{fontSize: "2.5rem", fontWeight: "bold"}}>Premium</h2>
                  </div>
                  <div className="card__desc">Automatize seu atendimento com nosso ChatBot de Inteligência Artificial, além de ter acesso a todos os recursos Pro.</div>
                </div>

                <div className="price">R$ 99,90 + 1.5%<span style={{color: "white"}}>/ mês</span></div>

                <ul className="featureList">
                  <li>Profissionais Ilimitados</li>
                  <li>Relatórios</li>
                  <li>Agendamento Online</li>
                  <li>Central de Atendimento</li>
                  <li>Agenda Visual</li>
                  <li>Notificações Agendamento</li>
                  <li>ChatBot de Atendimento</li>
                  <li>Suporte Prioritário</li>
                </ul>

                <button onClick={() => {handleOpenContractModal(2)}} style={{fontSize: "1.5rem"}} className="button button--white">Contratar</button>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Dialog open={contractModalOpen} onClose={handleCloseContractModal}>
        <DialogTitle style={{fontSize: "unset"}}>Formulário de Cadastro</DialogTitle>
        <DialogContent>
          <DialogContentText style={{fontSize: "unset"}}>
            Cadastre-se agora e comece a usar a solução A'Marko para gerenciar seus agendamentos de forma fácil e eficiente. 
          </DialogContentText>
          <TextField InputProps={{ style: { fontSize: "unset" } }} value={fantasyName} onChange={(event) => setFantasyName(event.target.value)} required id="fantasy_name" name="fantasy_name" label="Nome Fantasia" type="text" fullWidth variant="standard"/>
          <TextField InputProps={{ style: { fontSize: "unset" } }} value={cnpjCpf} onChange={handleChangeCnpjCpf} required margin="dense" id="cnpj_cpf" name="cnpj_cpf" label="CNPJ/CPF" type="text" fullWidth variant="standard"/>
          <TextField InputProps={{ style: { fontSize: "unset" } }} value={email} onChange={(event) => setEmail(event.target.value)} required margin="dense" id="email" name="email" label="Email" type="email" fullWidth variant="standard"/>
          <TextField InputProps={{ style: { fontSize: "unset" } }} value={phone} onChange={handleChangeTelefone} required margin="dense" id="phone" name="phone" label="Telefone" type="tel" fullWidth variant="standard"/>
          <Box sx={{ minWidth: 120, marginTop: "15px" }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="plans-form-select">Plano</InputLabel>
              <Select style={{fontSize: "unset"}} id="plans-select" value={planName} label="Plano" onChange={handleChangePlan}>
                <MenuItem style={{fontSize: "unset"}} value={0}>Básico</MenuItem>
                <MenuItem style={{fontSize: "unset"}} value={1}>Pro</MenuItem>
                <MenuItem style={{fontSize: "unset"}} value={2}>Premium</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <div>
            <Checkbox checked={optinTerms} onChange={handleChangeOptin} inputProps={{ 'aria-label': 'controlled' }}  {...checkboxLabel} /> <span>Ao criar uma conta, você concorda com nossos <UseTerms/> e nossa <Privacity/>.</span>
          </div>
        </DialogContent>
        <DialogActions>
          <Button style={{fontSize: "unset"}} onClick={handleCloseContractModal}>Cancelar</Button>
          <Button onClick={createContract} disabled={optinTerms && fantasyName && cnpjCpf && email && phone ? false : true} style={{fontSize: "unset"}} variant="contained" type="submit">Cadastrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
