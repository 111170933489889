import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function Privacity() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <b onClick={handleClickOpen}> Política de Privacidade</b>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Política de Privacidade
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            A sua privacidade é importante para nós. A seguir, explicamos como coletamos, usamos, compartilhamos e protegemos suas informações ao utilizar a plataforma A'Marko. Por favor, leia atentamente esta Política de Privacidade antes de usar nossos serviços.
          </Typography>

          <Typography gutterBottom>
            Informações Coletadas
          </Typography>
          <Typography gutterBottom>
            Ao utilizar os serviços do A'Marko, coletamos diferentes tipos de informações, incluindo:
          </Typography>
          <Typography gutterBottom>
            Informações Pessoais: nome, e-mail, telefone, CPF (quando necessário), endereço, entre outras, fornecidas por você ao criar uma conta ou realizar agendamentos.
          </Typography>
          <Typography gutterBottom>
            Informações de Uso: dados sobre sua interação com a plataforma, como histórico de agendamentos, navegação, horários de uso e preferências.
          </Typography>
          <Typography gutterBottom>
            Informações de Pagamento: detalhes fornecidos para processar pagamentos, como dados do cartão de crédito, que são gerenciados por terceiros de forma segura.
          </Typography>

          <Typography gutterBottom>
            Como Usamos Suas Informações
          </Typography>
          <Typography gutterBottom>
            As informações que coletamos são utilizadas para:
          </Typography>
          <Typography gutterBottom>
            Prover e melhorar nossos serviços, garantindo a melhor experiência de uso.
          </Typography>
          <Typography gutterBottom>
            Facilitar a gestão de agendamentos, comunicação e atendimento ao cliente.
          </Typography>
          <Typography gutterBottom>
            Enviar notificações, atualizações e comunicações importantes sobre a plataforma.
          </Typography>
          <Typography gutterBottom>
            Processar pagamentos e gerenciar assinaturas dos planos A'Marko.
          </Typography>
          <Typography gutterBottom>
            Gerar relatórios e análises para otimização dos serviços.
          </Typography>
          <Typography gutterBottom>
            Cumprir requisitos legais e regulamentares.
          </Typography>

          <Typography gutterBottom>
            Compartilhamento de Informações
          </Typography>
          <Typography gutterBottom>
            A'Marko pode compartilhar suas informações nas seguintes situações:
          </Typography>
          <Typography gutterBottom>
            Com Prestadores de Serviços: parceiros terceirizados que nos ajudam a oferecer funcionalidades essenciais, como processamento de pagamentos e hospedagem de dados.
          </Typography>
          <Typography gutterBottom>
            Com Salões de Beleza e Profissionais: informações relevantes podem ser compartilhadas com os salões ou profissionais com os quais você agendar serviços.
          </Typography>
          <Typography gutterBottom>
            Por Exigência Legal: quando necessário para cumprir obrigações legais ou proteger nossos direitos legais.
          </Typography>

          <Typography gutterBottom>
            Em nenhum momento venderemos ou alugaremos suas informações pessoais a terceiros.
          </Typography>

          <Typography gutterBottom>
            Segurança de Dados
          </Typography>
          <Typography gutterBottom>
            A segurança das suas informações é uma prioridade para nós. Utilizamos medidas de segurança técnicas e administrativas para proteger seus dados contra acesso não autorizado, destruição, perda ou alteração. No entanto, nenhum método de transmissão de dados pela internet é 100% seguro, e embora façamos o possível para proteger suas informações, não podemos garantir segurança absoluta.
          </Typography>

          <Typography gutterBottom>
            Cookies e Tecnologias Similares
          </Typography>
          <Typography gutterBottom>
            Utilizamos cookies e tecnologias semelhantes para coletar informações sobre como você utiliza nosso site. Os cookies ajudam a melhorar sua experiência, lembrar preferências e fornecer conteúdo personalizado. Você pode controlar o uso de cookies por meio das configurações do seu navegador.
          </Typography>

          <Typography gutterBottom>
            Seus Direitos
          </Typography>
          <Typography gutterBottom>
            Como usuário, você tem o direito de:
          </Typography>
          <Typography gutterBottom>
            Acessar as informações que coletamos sobre você.
          </Typography>
          <Typography gutterBottom>
            Solicitar a correção de dados incorretos ou desatualizados.
          </Typography>
          <Typography gutterBottom>
            Pedir a exclusão de sua conta e dados pessoais.
          </Typography>
          <Typography gutterBottom>
            Restringir ou limitar o uso de determinadas informações.
          </Typography>
          <Typography gutterBottom>
            Solicitar a portabilidade dos seus dados para outro serviço.
          </Typography>
          <Typography gutterBottom>
            Para exercer esses direitos, entre em contato com nosso suporte através dos canais indicados no site.
          </Typography>

          <Typography gutterBottom>
            Retenção de Dados
          </Typography>
          <Typography gutterBottom>
            Nós retemos suas informações pessoais enquanto sua conta estiver ativa ou conforme necessário para cumprir com as finalidades descritas nesta política. Caso você opte por encerrar sua conta, excluiremos ou anonimizaremos suas informações, exceto quando houver obrigações legais que exijam sua retenção.
          </Typography>

          <Typography gutterBottom>
            Alterações nesta Política de Privacidade
          </Typography>
          <Typography gutterBottom>
            Podemos atualizar esta Política de Privacidade periodicamente para refletir mudanças em nossas práticas de dados. Quando isso ocorrer, publicaremos a nova versão no site e indicaremos a data da última revisão. Recomendamos que você revise esta política regularmente para se manter informado.
          </Typography>

          <Typography gutterBottom>
            Contato
          </Typography>
          <Typography gutterBottom>
            Se você tiver dúvidas sobre esta Política de Privacidade ou sobre o tratamento de suas informações, entre em contato conosco pelo e-mail suporte@amarko.com.
          </Typography>

          <Typography gutterBottom>
            Data da última atualização: 12/09/2024
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}